/*
 * @Desc:
 * @Author: JacksonZhou
 * @Date: 2021/11/28
 * @LastEditTime: 2022/01/04
 */
import { graphql, Link } from "gatsby";
import * as React from "react";
import { Timeline } from "antd";
import * as dayjs from "dayjs";
import Layout from "../components/layout";
import "../css/archive.css";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  let posts = data.allMarkdownRemark.nodes.sort((a, b) => {
    return dayjs(a.frontmatter.createTime > b.frontmatter.createTime ? -1 : 1);
  });

  let newPosts: any = new Map();
  // 二维数组，按月份划分
  posts.forEach((post) => {
    const createMonth = dayjs(post.frontmatter.createTime).format("YYYY/MM");
    if (!newPosts.get(createMonth)) {
      newPosts.set(createMonth, [post]);
    } else {
      newPosts.set(createMonth, [...newPosts.get(createMonth), post]);
    }
  });
  newPosts = Array.from(newPosts);

  return (
    <Layout location={location} title={siteTitle}>
      {newPosts.map((post) => {
        return (
          <div key={post[0]}>
            <h4 className="archive-group-title">{post[0]}</h4>
            <Timeline>
              {post[1].map((item) => {
                return (
                  <Timeline.Item key={item.frontmatter.createTime}>
                    <div
                      style={{
                        width: "70%",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Link to={item.fields.slug}>
                        {item.frontmatter.title}
                      </Link>
                      {item.frontmatter.createTime}
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        );
      })}
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { group: { eq: "blog" } } }
      sort: { fields: [frontmatter___createTime], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          createTime(formatString: "YYYY/MM/DD")
          updateTime(formatString: "YYYY/MM/DD")
          title
          type
        }
      }
    }
  }
`;
